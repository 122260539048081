<template>
    <div class="record-container">
        <div class="record-content">
            <el-table :data="classRecordsList" border style="width: 100%; flex: 1; margin-top: 20px" height="1%"  size="medium" :header-cell-style="{fontWeight: 'normal', color: '#333', background: '#f5f7fa', textAlign: 'center'}" :cell-style="{fontSize: '12px',color: '#333', textAlign: 'center'}">
                <el-table-column prop="id" label="课程序号"></el-table-column>
                <el-table-column prop="course_name" label="课程名称" width="300px"></el-table-column>
                <el-table-column prop="c_name" label="授课班级"></el-table-column>
                <el-table-column label="考勤">
                    <template slot-scope="scope">
                        <el-button type="text" @click="toAttend(scope.row)">{{scope.row.attend}}</el-button>
                    </template>
                </el-table-column>
                <el-table-column label="讨论">
                    <template slot-scope="scope">
                        <el-button type="text" @click="toDiscuss(scope.row)">{{scope.row.discuss}}</el-button>
                    </template>
                </el-table-column>
                <el-table-column label="小测">
                    <template slot-scope="scope">
                        <el-button type="text" @click="toQuiz(scope.row)">{{scope.row.quiz}}</el-button>
                    </template>
                </el-table-column>
                <el-table-column label="实训">
                    <template slot-scope="scope">
                        <el-button type="text" @click="toTrain(scope.row)">{{scope.row.training}}</el-button>
                    </template>
                </el-table-column>
                <el-table-column prop="tran_start_time" label="授课时间"></el-table-column>
            </el-table>
            <el-pagination class="pages-center" background style="margin: 20px 0;"
                           :current-page.sync="recordsPages.currentPageNum"
                           :page-size="recordsPages.eachPageNum"
                           :total="recordsPages.total"
                           layout="prev, pager, next, jumper"
                           @current-change="recordCurrentChange">
            </el-pagination>
        </div>
    </div>
</template>

<script>
    export default {
        name: "classRecord",
        data() {
            return {
                classRecordsList: [],
                recordsPages: {
                    currentPageNum: 1,
                    eachPageNum: 10,
                    total: 0,
                },
            }
        },
        mounted() {
            this.getRecordsList();
        },
        methods: {
            getRecordsList() {
                let params = {
                    page: this.recordsPages.currentPageNum,
                    limit: this.recordsPages.eachPageNum,
                };
                this.$shttp.axiosGetBy(this.$api.getClassRecord, params, (res) => {
                    if (res.code === 200) {
                        this.classRecordsList = res.data.data
                        this.recordsPages.total = res.data.total
                    } else {
                        this.$message.warning(res.msg)
                    }
                }, (err) => {
                    console.log(err)
                })
            },
            recordCurrentChange(val) {
                this.recordsPages.currentPageNum = val;
                this.getRecordsList();
            },
            toAttend(row) {
                this.$router.push({path: '/qualityCourse/recordDetail', query: {recordId: row.id, courseName: row.course_name}})
            },
            toDiscuss(row) {
                this.$router.push({path: '/teacherClass/discussDetail', query: {c_id: row.c_id, className: row.c_name, course_record_id: row.id}})
            },
            toQuiz(row) {
                this.$router.push({path: '/teacherClass/quizList', query: {classId: row.c_id, className: row.c_name, recordId: row.id}})
            },
            toTrain(row) {
                let params = {
                    c_id: row.c_id,
                    course_record_id: row.id,
                }
                this.$shttp.axiosGetBy(this.$api.showSignInList, params, (res) => {
                    if (res.code === 200) {
                        this.$router.push({path: '/teacherClass/practicalList', query: {classId: row.c_id, courseName: row.course_name, className: row.c_name, recordId: row.id}})
                    } else {
                        this.$message.warning(res.msg)
                    }
                }, (err) => {
                    console.log(err)
                })
            },
        }
    }
</script>

<style scoped lang="scss">
    .record-container {
        height: calc(100% - 40px);
        background: #F2F2F2;
        padding: 20px 0;
        .record-content {
            height: 100%;
            display: flex;
            flex-direction: column;
            width: 1200px;
            margin: 0 auto;
            background: #fff;
        }
    }
</style>